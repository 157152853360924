@import 'styles/normilize';
@import 'libs/fontawesome/css/all.min.css';

*,
*::before,
*::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

html {
    font-size: 16px;
}

body {
    width: 100%;
    // letter-spacing: 0.4px;
    overflow-x: hidden;
    line-height: normal;
    font-family: "RobotoRegular", sans-serif;
    background-color: #363636;
    color: #FFFFFF;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=number],
input[type=url],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input::-ms-clear {
    display: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

button {
    background-color: transparent;
    border: none;
    padding: 0;
}

button,
a,
input {
    font-family: "RobotoRegular", sans-serif;
    text-decoration: none;
    &:focus {
        outline: none;
    }
}

.container {
    // max-width: rem(1440);
    // padding: 0 rem(40);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    div[data-name="container"] {
        max-width: 1187px;
    }
}

@media (max-width: 1024px) {
    div[data-name="container"] {
        max-width: 960px;
    }
}

@media (max-width: 986px) {
    div[data-name="container"] {
        max-width: 890px;
    }
}

@media (max-width: 914px) {
    div[data-name="container"] {
        max-width: 840px;
    }
}

@media (max-width: 864px) {
    div[data-name="container"] {
        max-width: 800px;
    }
}

::-webkit-scrollbar-button {
    width: 5px;
    height: 0px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
}

::-webkit-scrollbar-thumb {
    background-color: #262424;
}

::-webkit-resizer {
    width: 5px;
    height: 0px;
}

::-webkit-scrollbar {
    width: 5px
}
